import React, { useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import VideoIcon from '@atlaskit/icon/core/video';
import { SkeletonItem } from '@atlaskit/menu';
import { useIsReducedMotion } from '@atlaskit/motion';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { getHashedLoomVideoIdFromUrl } from '@atlassian/analytics-data-privacy';

import { RecordLoomPopoverItemSpotlight } from '@confluence/loom-onboarding';
import { withFlags, type WithFlagsProps } from '@confluence/flags';
import { useLoomEntryPointVariant, useLoomRecorderEntryPoint } from '@confluence/loom-utils';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { useSpaceId } from '@confluence/space-utils';
import { useSkippableCoordination } from '@confluence/skippable-coordination-client';
import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';
import { fg } from '@confluence/feature-gating';

import { buildCreationLink } from '../../CreateContentRouteComponent';

import { LoadingPopoverItem } from './LoadingPopoverItem';
import {
	ContentIconWrapper,
	ContentTitle,
	TitleContainer,
	MenuItemWrapper,
} from './popoverItemStyles';

const i18n = defineMessages({
	openingLoomRecorder: {
		id: 'create-blank-fabric-page.create.content.loom-video.opening-loom-recorder',
		defaultMessage: 'Opening Loom recorder',
		description:
			'text announced to screen readers while waiting for the Loom recording UI to open after clicking the Loom video button in a create menu',
	},
	loomVideoTitle: {
		id: 'create-blank-fabric-page.create.content.loom-video.title',
		defaultMessage: 'Loom video',
		description:
			'title of the global or contextual create menu item indicating that this option will trigger a Loom video recording flow',
	},
});

const iconWrapperStyles = xcss({
	display: 'flex',
	flexShrink: 0,
});

export const useIsCreateMenuLoomRecorderEntryPointEnabled = () => {
	const { entryPointVariant } = useLoomEntryPointVariant();
	return ['BETA', 'CO_USE', 'CROSS_JOIN'].includes(entryPointVariant);
};

type RecordLoomPopoverItemProps = {
	entryPointLocation: string;
	onClick: (actionSubjectId: string) => void;
	onRecorderOpenComplete?: () => void;
	source: string;
	spaceKey?: string;
	parentId?: string;
	withFallback?: boolean;
	isDisabled?: boolean;
	location?: string;
};

const RecordLoomPopoverItemComponent = ({
	entryPointLocation,
	flags,
	onClick,
	onRecorderOpenComplete,
	source,
	spaceKey,
	parentId,
	withFallback = false,
	isDisabled = false,
	location = '',
}: RecordLoomPopoverItemProps & WithFlagsProps) => {
	const intl = useIntl();
	const prefersReducedMotion = useIsReducedMotion();
	const { push } = useRouteActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const spaceId = useSpaceId();
	const [isGASpotlightOpen, setIsGASpotlightOpen] = useState(true);

	const { enforceStorageLimit, shouldBlockCreate } = useHardStorageEnforcement({
		source: 'record-loom-popover-item',
	});

	const prevIsOpeningRecorder = useRef(false);

	const { openLoomRecorder, isLoomRecorderInitialized, isOpeningRecorder, hasRecordingAccess } =
		useLoomRecorderEntryPoint({ entryPointLocation, flags });

	const shouldShowLoomGAOnboarding =
		isLoomRecorderInitialized && hasRecordingAccess && fg('confluence_m25_onboarding_spotlights');

	const [isLoomGAOnboardingActive, stopLoomGAOnboarding] = useSkippableCoordination(
		'cc-loom-onboarding-spotlight-creation',
		!shouldShowLoomGAOnboarding || shouldBlockCreate,
	);

	useEffect(() => {
		if (prevIsOpeningRecorder.current && !isOpeningRecorder) {
			onRecorderOpenComplete?.();
		}
		prevIsOpeningRecorder.current = isOpeningRecorder;
	}, [isOpeningRecorder, onRecorderOpenComplete]);

	const handleSpotlightClose = useCallback(() => {
		if (isLoomGAOnboardingActive) {
			setIsGASpotlightOpen(false);
			void stopLoomGAOnboarding();
		}
	}, [stopLoomGAOnboarding, isLoomGAOnboardingActive]);

	const handleClick = useCallback(() => {
		onClick('createLoomPopoverItem');

		void openLoomRecorder({
			onInsert: (videoUrl: string) => {
				const createUrl = buildCreationLink({
					contentType: 'smartLinkEmbed',
					parentPageId: parentId,
					...(withFallback && { withFallback }),
					smartLinkCreateSource: 'loomRecordSDK',
					smartLinkEmbedUrl: videoUrl,
					source,
					spaceKey,
				});

				push(createUrl);

				void getHashedLoomVideoIdFromUrl(videoUrl).then((hashedVideoId) => {
					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							action: 'insertVideo',
							actionSubject: 'loom',
							source, // {globalCreateDropdown|pageTreeItemContextualCreate|spaceLevelContextualCreate}-smartLinkEmbed
							objectType: 'embed',
							containerType: 'space',
							containerId: spaceId, // spaceKey can have UGC so use spaceId
							attributes: {
								location: 'contentTreeItem',
								video_id: hashedVideoId,
							},
						},
					}).fire();
				});
			},
			// intentionally not localized because Loom RecordSDK does not support i18n
			insertButtonText: 'Insert video',
			location,
		});
	}, [
		onClick,
		openLoomRecorder,
		parentId,
		push,
		source,
		spaceKey,
		withFallback,
		createAnalyticsEvent,
		spaceId,
		location,
	]);

	const handleClickWithStorageEnforcement = enforceStorageLimit(handleClick);

	const menuItem = !isLoomRecorderInitialized ? (
		<MenuItemWrapper isDisabled={isDisabled}>
			<SkeletonItem
				hasIcon
				isShimmering={!prefersReducedMotion}
				testId="skeleton-create-menu-button-item"
			/>
		</MenuItemWrapper>
	) : (
		<MenuItemWrapper isDisabled={isDisabled}>
			<LoadingPopoverItem
				iconBefore={
					<ContentIconWrapper>
						<Box xcss={iconWrapperStyles}>
							<VideoIcon label="" color={token('color.icon.subtle')} />
						</Box>
					</ContentIconWrapper>
				}
				isLoading={isOpeningRecorder}
				loadingLabel={intl.formatMessage(i18n.openingLoomRecorder)}
				onClick={handleClickWithStorageEnforcement}
				testId="create-menu-button-item"
				isDisabled={isDisabled}
			>
				<TitleContainer>
					<ContentTitle>
						<FormattedMessage {...i18n.loomVideoTitle} />
					</ContentTitle>
				</TitleContainer>
			</LoadingPopoverItem>
		</MenuItemWrapper>
	);

	if (shouldShowLoomGAOnboarding && isLoomGAOnboardingActive && isGASpotlightOpen) {
		return (
			<RecordLoomPopoverItemSpotlight
				onRecord={handleClickWithStorageEnforcement}
				onClose={handleSpotlightClose}
				source={source}
			>
				{menuItem}
			</RecordLoomPopoverItemSpotlight>
		);
	}

	return menuItem;
};

export const RecordLoomPopoverItem = withFlags(RecordLoomPopoverItemComponent);
