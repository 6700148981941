import React, { useContext, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/core/close';
import QuestionCircleIcon from '@atlaskit/icon/core/question-circle';
import Image from '@atlaskit/image';
import Modal from '@atlaskit/modal-dialog';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import { SPAViewContext } from '@confluence/spa-view-context';
import type { PaywallButtonActionTypes } from '@confluence/whiteboard-utils';

import type { ChangeEditionSectionMessageProps } from '../SectionMessage/ChangeEditionSectionMessage';
import { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';
import { Card } from '../UpsellLooknFeel/Card';

import WhiteboardsPaywallImg from './images/WhiteboardsPaywall.svg';
import WhiteboardsPaywallDarkImg from './images/WhiteboardsPaywallDark.svg';

export const i18n = defineMessages({
	closeButtonLabel: {
		id: 'change-edition.whiteboards-upsell.modal.closeButtonLabel',
		defaultMessage: 'Close modal',
		description:
			"ARIA label for a button with a 'X' icon in a modal header letting the user know that clicking it will close the modal.",
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardWrapper = styled.div({
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage
	borderRadius: `0 0 ${token('border.radius', '3px')} ${token('border.radius', '3px')}`,
});

type RequiredSharedProps = Required<
	Pick<
		ChangeEditionSectionMessageProps,
		'learnMoreLink' | 'secondaryLink' | 'source' | 'title' | 'upgradeEditionActionSubjectId'
	>
>;

export type WhiteboardsActiveBoardLimitModalProps = React.PropsWithChildren<
	RequiredSharedProps & {
		learnMoreLink: Required<
			Pick<RequiredSharedProps['learnMoreLink'], 'href' | 'messageDescriptor'>
		>;
		onClose?: (action?: PaywallButtonActionTypes) => void;
		onCloseComplete?: () => void;
	}
>;

const WhiteboardsActiveBoardLimitModalContents = ({
	children,
	learnMoreLink,
	secondaryLink,
	onClose,
	source,
	title,
	upgradeEditionActionSubjectId,
}: WhiteboardsActiveBoardLimitModalProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const { isSiteAdmin } = useContext(SPAViewContext);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: source,
			},
		}).fire();
	}, [createAnalyticsEvent, source]);

	const fireLearnMoreClickedAnalytics = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: learnMoreLink.actionSubjectId,
				source,
			},
		}).fire();
	};

	const closeButton = (
		<Button
			appearance="subtle"
			iconBefore={
				<CrossIcon
					label={formatMessage(i18n.closeButtonLabel)}
					color={token('color.text.subtle')}
				/>
			}
			onClick={() => {
				onClose?.('close');
			}}
			spacing="none"
		/>
	);

	return (
		<CardWrapper>
			<Card
				body={<>{children}</>}
				learnMoreLink={{
					...learnMoreLink,
					appearance: learnMoreLink.appearance || 'link',
				}}
				secondaryLink={{
					...secondaryLink,
					appearance: secondaryLink.appearance || (isSiteAdmin ? 'link' : 'primary'),
				}}
				source={source}
				targetEdition={ConfluenceEdition.PREMIUM}
				title={title}
				upgradeEditionActionSubjectId={upgradeEditionActionSubjectId}
				headerButton={
					<>
						{isSiteAdmin && (
							<Tooltip content={formatMessage(learnMoreLink.messageDescriptor)}>
								{(tooltipProps) => (
									<Button
										appearance="subtle-link"
										href={learnMoreLink.href}
										iconBefore={
											<QuestionCircleIcon
												label={formatMessage(learnMoreLink.messageDescriptor)}
												color={token('color.text.subtle')}
											/>
										}
										spacing="none"
										target="_blank"
										{...tooltipProps}
										onClick={(event) => {
											tooltipProps?.onClick?.(event);
											fireLearnMoreClickedAnalytics();
										}}
									/>
								)}
							</Tooltip>
						)}
						{closeButton}
					</>
				}
			>
				<Image
					src={WhiteboardsPaywallImg}
					srcDark={WhiteboardsPaywallDarkImg}
					alt=""
					width="100%"
				/>
			</Card>
		</CardWrapper>
	);
};

const WrappedWhiteboardsActiveBoardLimitModalContents = withErrorBoundary({
	attribution: Attribution.CONTENT_TYPES,
})(WhiteboardsActiveBoardLimitModalContents);

export const WhiteboardsActiveBoardLimitModal = (props: WhiteboardsActiveBoardLimitModalProps) => (
	<Modal
		onClose={() => props.onClose?.('close')}
		onCloseComplete={props.onCloseComplete}
		shouldScrollInViewport
		testId="whiteboards-upsell-modal"
		width="440px"
	>
		<WrappedWhiteboardsActiveBoardLimitModalContents {...props} />
	</Modal>
);
